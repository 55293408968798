'use client';
import { Default } from 'components/layouts/Default';
import { useAccount, useConnect } from 'wagmi';
import type { NextPage } from 'next';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDropzone } from 'react-dropzone';
import { animateScroll } from 'react-scroll';
import { auth } from '@/configs/firebase';

import {
  Box,
  Input,
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
  useDisclosure,
  Image,
  Divider,
  Avatar,
  useBoolean,
  ModalFooter,
} from '@chakra-ui/react';
import { Platform } from '@/components/modules/DashBoard/elements';
import Loading from '@/components/modules/DashBoard/elements/Loading';
import { DashBoardProvider } from '@/components/modules/DashBoard/contexts/DashBoardContext';
import { basicConfig } from '@/configs';
import { DashBoard } from '@/components/modules/DashBoard';
import axios from 'axios';
import * as R from 'ramda';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import PhoneDrawer from '@/components/modules/PhoneDrawer';
import SideInfoRight from '@/components/SideInfo/SideInfoRight';
import { useNetwork } from 'wagmi';
import { isEOA } from '@/utils/isEOA';
import { useGetNftData } from '@/components/modules/DashBoard/hooks/useGetNftData';
import { NftModel } from '@/components/modules/DashBoard/interfaces';
import { getEllipsisTxt, isValidEmail } from '@/utils/format';
import { completionEmail, getProjectInfo } from '../api/enterpriseapi';
import { getUserClaimStatus } from '../api/userClaim';
import { HamburgerIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import MenuIcon from '@/components/icons/MenuIcon';
import ExpandIcon from '@/components/icons/ExpandIcon';
import { ResponsiveCarousel } from '@/components/constomer/ADSlider/ADSlider';
import { useBluwhale } from '@/context/BluwhaleContext';
import { useGetConsumerCompaigns } from '@/hooks/useGetConsumerCompaigns';
import { whoHuntYouOut } from '../api/consumer_points';
import Slick from '@/components/modules/DashBoard/elements/Slick';
import { Trending } from '@/components/constomer/Trending';
import { MarketList } from '@/components/constomer/MarketList';
import FuzzySearchInput from '@/components/constomer/FuzzySearchInput';

type CreatedGroupData = {
  group_id: string;
  name: string;
  wallets: { address: string }[];
};

const HomePage: NextPage = () => {
  const toast = useToast();
  const router = useRouter();
  const { chain } = useNetwork();
  const { address: pluginAddress } = useAccount();
  const { user, onLogout, axiosInstance } = useBluwhale();
  const { address: myAddress } = user ?? {};
  const [scrollToWhoHuntedYouY, setScrollToWhoHuntedYouY] = useState(basicConfig.scrollTop.md);
  const { address: loadedAddress } = router.query;
  const { data: inbox, refetch } = useGetConsumerCompaigns();
  const [isExpand, setIsPand] = useBoolean();
  const [claimed, setClaimed] = useState(false);
  const { isOpen: isUploadUsersOpen, onOpen: onUploadUsersOpen, onClose: onUploadUsersClose } = useDisclosure();
  const { isOpen: isProcessing, onOpen: openProcessingGroup, onClose: closeProcessing } = useDisclosure();
  const { isOpen: showLogout, onOpen: openLogout, onClose: closeLogout } = useDisclosure();
  // switch my profile and spots market
  const [useSpots, setUseSpots] = useState(false);

  const {
    isOpen: isTooManyRequestsOpen,
    onOpen: onTooManyRequestsOpen,
    onClose: onTooManyRequestsClose,
  } = useDisclosure();

  const [email, setEmail] = useState('');
  const [searchProjectInfo, setProjectInfo] = useState<NftModel | undefined>(undefined);

  const { fetchOpenSeaNftsByContract } = useGetNftData({});
  // ** State
  const [files, setFiles] = useState<File[]>([]);
  const [address, setAddress] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [whoHuntedYouList, setWhoHuntedYouList] = useState([]);
  const [savedGroupList, setSavedGroupList] = useState([]);
  const [createdGroupList, setCreatedGroupList] = useState<CreatedGroupData[]>([]);
  const [loadingCheckoutAddressList, setLoadingCheckoutAddressList] = useState(false);
  const [checkoutAddressList, setCheckoutAddressList] = useState([]);
  const [loadingSavedGroupList, setLoadingSavedGroupList] = useState(false);
  const [loadingCreatedGroupList, setLoadingCreatedGroupList] = useState(false);
  const [isHunted, setIsHunted] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const [wallet, setWallet] = useState({
    address: '',
    color: randomColor,
    tags: [],
    transactions: [],
  });

  const isDemo = address === basicConfig.demo.address;

  // @ts-ignore
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleUploadEmail = async () => {
    if (isValidEmail(email)) {
      try {
        //@ts-ignore
        const res = await completionEmail(loadedAddress, email);
        toast({
          description: 'Once this project processed, we will notify you',
          // description: (e as { message: string })?.message,
          status: 'success',
          position: 'top',
        });
      } catch (error) {
        toast({
          description: 'Once this project processed, we will notify you',
          // description: (e as { message: string })?.message,
          status: 'success',
          position: 'top',
        });
      }
    } else {
      toast.closeAll();
      toast({
        description: 'Please leave a valid email address',
        // description: (e as { message: string })?.message,
        status: 'error',
        position: 'top',
      });
    }
  };
  useEffect(() => {
    if (window.innerWidth > basicConfig.scrollTop.innerWidth) {
      setScrollToWhoHuntedYouY(basicConfig.scrollTop.md);
    } else {
      setScrollToWhoHuntedYouY(basicConfig.scrollTop.xl);
    }
  });

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        if (!useSpots) {
          window.scrollTo({ left: document.body.scrollWidth, behavior: 'smooth' });
        } else {
          window.scrollTo({ left: 0, behavior: 'smooth' });
        }
      }
    }
    handleResize();
  }, [useSpots]);

  const getSavedGroupList = useCallback(async (myWallet: string) => {
    setLoadingSavedGroupList(true);

    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (bearerToken) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${bearerToken}`,
      };
      const config = {
        headers,
      };

      const response = await axios.get(`${basicConfig.bluwhale.walletAPIUrl}/wallets/hunting/saved/`, config);
      const result = response.data.groups;
      setLoadingSavedGroupList(false);
      return result;
    }
    return [];
  }, []);

  const getCreatedGroupList = useCallback(async (myWallet: string) => {
    setLoadingCreatedGroupList(true);

    const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
    const token = user ? JSON.parse(user).accessToken : undefined;
    const bearerToken = token ? `Bearer ${token}` : undefined;
    if (user && bearerToken) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${bearerToken}`,
      };
      const config = {
        headers,
      };

      const response = await axios.get(`${basicConfig.bluwhale.walletAPIUrl}/wallets/hunting/created/`, config);
      const result = response.data.groups;

      setLoadingCreatedGroupList(false);
      return result;
    }
    return [];
  }, []);

  const getCheckoutList = useCallback(async (checkoutWallet: string) => {
    setLoadingCheckoutAddressList(true);
    const response = await axios.post(
      `${basicConfig.bluwhale.walletAPIUrl}/wallets/also_check_out/`,
      { wallet: checkoutWallet },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
    // console.log('🚀 ~ file: Default.tsx:55 ~ getCheckoutList ~ response:', response);
    setLoadingCheckoutAddressList(false);

    return response.data.projects;
  }, []);

  useEffect(() => {
    if (loadedAddress) {
      const [myGroup] = createdGroupList;
      setIsHunted(myGroup?.wallets?.map((item) => item.address).includes(R.toLower(loadedAddress as string)));
    }
  }, [createdGroupList, loadedAddress]);

  useEffect(() => {
    if (loadedAddress) {
      // @ts-ignore
      setAddress(loadedAddress || '');
      // @ts-ignore
      setWallet({ ...wallet, address: loadedAddress });
      getSavedGroupList((myAddress as string) || '0x0').then((resp) => setSavedGroupList(resp));
      getCreatedGroupList((myAddress as string) || '0x0').then((resp) => setCreatedGroupList(resp));
      getCheckoutList(myAddress as string).then((resp) => setCheckoutAddressList(resp));
    }
  }, [loadedAddress]);

  useEffect(() => {
    if (loadedAddress && basicConfig.demo.address !== loadedAddress) {
      //@ts-ignore
      checkEOAorContract(loadedAddress);
    }
  }, [loadedAddress]);

  const checkEOAorContract = async (address: string) => {
    const isAddress = await isEOA(address);
    //@ts-ignore
    if (!isAddress && !checkoutAddressList.some((obj) => obj.address === address)) {
      openProcessingGroup();
      const demoProject = '0x789e35a999c443fe6089544056f728239b8ffee7';
      const { contract_address } = await getProjectInfo(address);
      if (address.toLowerCase() !== demoProject && contract_address === demoProject) {
        const res = await fetchOpenSeaNftsByContract(address);
        setProjectInfo(res);
      } else {
        router.push(`/group/${address}`);
      }
    }
  };
  // @ts-ignore
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleUsersImport = () => {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('csvfile', files[0]);

    fetch('https://web3-wallet-dashboard-api-q67p7dk34q-uc.a.run.app/api/v1/wallets/csv/', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        const { total, users } = resData.data;

        window.sessionStorage.setItem('users', JSON.stringify(users));
        window.sessionStorage.setItem('total', JSON.stringify(total));

        event('btn_import_users_submit');
        // setUsers(newUsers);
        onUploadUsersClose();
        router.push('/users');
      })
      .catch((e) => {
        console.error('error', e);
        toast.closeAll();
        toast({
          description: 'The upload has failed; please try again',
          // description: (e as { message: string })?.message,
          status: 'error',
          position: 'top',
        });
        setFiles([]);
        /*發生錯誤時要做的事情*/
      })
      .finally(() => {
        setIsUploaded(false);
      });
  };

  const onDrop = (newFiles: File[]) => {
    console.log('🚀 ~ file: Home.tsx:38 ~ onDrop ~ files:', files);
    event('drag_drop');

    setFiles(newFiles);
    setIsUploaded(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDrop,
    accept: {
      'text/csv': [],
    },
  });

  const handleSearchClick = async () => {
    if (!wallet || !address || address.slice(0, 2) !== '0x') {
      toast.closeAll();
      toast({
        description: 'Invalid wallet address',
        position: 'top',
        status: 'error',
      });
      return;
    }
    event(events.btn_profile_now, {
      wallet_address: `.${address}.`,
    });

    // @ts-ignore
    setWallet({ ...wallet, address, color: Math.floor(Math.random() * 16777215).toString(16) });
    router.push(`/wallet/${address}`);
  };

  const handleUploadingUsersClick = () => {
    event(events.btn_import_users);
    onUploadUsersOpen();
  };

  const handleTooManyRequestsConfirm = () => {
    onTooManyRequestsClose();
  };

  // eslint-disable-next-line complexity
  const handleFollowClick = async (address: string) => {
    if (!isHunted || !myAddress) {
      event(events.btn_follow, {
        address: `.${address}.`,
      });
      const payloadData = {
        group_id: 'can_be_ignored_for_now',
        wallet_address_to_add: address,
      };

      if (!myAddress) {
        event(events.connectwallet_display, {
          source: 'btn_follow',
        });
        router.push('/consumer/login?type=web3');
        return;
      }

      const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
      const token = user ? JSON.parse(user).accessToken : undefined;
      const bearerToken = token ? `Bearer ${token}` : undefined;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${bearerToken}`,
      };
      const config = {
        headers,
      };

      try {
        const response = await axios.post(
          `${basicConfig.bluwhale.walletAPIUrl}/wallets/hunting/add_wallet_to_group/`,
          payloadData,
          config,
        );
        toast.closeAll();
        toast({
          description: `Followed Successfully`,
          status: 'success',
          position: 'top',
        });
        await getCreatedGroupList((myAddress as string) || '0x0').then((groupsResponse) => {
          const groups = groupsResponse;
          // @ts-ignore
          const groupsWithEffect = groups.map((group) => ({
            ...group,
            // @ts-ignore
            wallets: group.wallets.map((respWallet) => ({
              ...respWallet,
              ...(address === respWallet.address && { isNew: true }),
            })),
          }));

          // console.log('🚀 ~ file: [address].tsx:328 ~ getCreatedGroupList ~ responseWithEffect:', groupsWithEffect);

          setCreatedGroupList(groupsWithEffect);

          setTimeout(() => {
            setCreatedGroupList(groups);
          }, 3000);
        });
        console.log('🚀 ~ file: index.tsx:832 ~ handleFollowClick ~ response:', response);
        // await animateScroll.scrollTo(scrollToWhoHuntedYouY);
      } catch (error) {
        toast({
          // @ts-ignore
          description: error?.response?.data?.detail || 'Something went wrong; please try again later.',
          status: 'error',
          position: 'top',
        });
      }
    } else {
      event(events.btn_un_follow, {
        address: `.${address}.`,
      });
      const payloadData = {
        group_id: 'can_be_ignored_for_now',
        wallet_address_to_remove: address,
      };
      const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
      const token = user ? JSON.parse(user).accessToken : undefined;
      const bearerToken = token ? `Bearer ${token}` : undefined;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${bearerToken}`,
      };
      const config = {
        headers,
      };
      try {
        const response = await axios.post(
          `${basicConfig.bluwhale.walletAPIUrl}/wallets/hunting/remove_wallet_from_group/`,
          payloadData,
          config,
        );
        toast.closeAll();
        toast({
          description: `Unfollowed successfully`,
          status: 'success',
          position: 'top',
        });
        getCreatedGroupList((myAddress as string) || '0x0').then((resp) => setCreatedGroupList(resp));
        console.log('🚀 ~ file: index.tsx:832 ~ handleFollowClick ~ response:', response);
      } catch (error) {
        toast({
          // @ts-ignore
          description: error?.response?.data?.detail || 'Something went wrong; please try again later.',
          status: 'error',
          position: 'top',
        });
      }
    }
    // const result = response.data.groups;
  };

  const handleError = () => {
    event(events.rate_limit_exceed);
  };
  // console.log('🚀 ~ file: [address].tsx:454 ~ whoHuntedYouList:', whoHuntedYouList);

  const toggleSearchDisplay = () => {
    setShowSearch(!showSearch);
  };
  useEffect(() => {
    if (loadedAddress) {
      event(events.first_entry, {
        source: loadedAddress !== basicConfig.demo.address ? `.${loadedAddress}.` : 'demo',
      });
    }
  }, [loadedAddress]);

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && user?.user_type === 'consumer') || false;
  }
  const fetchClaimStatus = async () => {
    //@ts-ignore
    try {
      if (!getEnable()) {
        return undefined;
      }
      if (!axiosInstance) return;
      //@ts-ignore
      const { claimed = false } = await getUserClaimStatus(myAddress, axiosInstance);
      setClaimed(claimed);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        onLogout();
        router.push('/login');
      }
      throw error;
    }
  };

  useEffect(() => {
    if (myAddress) {
      fetchClaimStatus();
      refetch();
    }
  }, [myAddress]);

  useEffect(() => {
    refetch();
  }, [isExpand]);

  const isMe = myAddress?.toLowerCase() === wallet?.address?.toLowerCase();

  const handleBackClick = () => {
    router.replace(`/wallet/${myAddress}`);
  };

  const getWhoHuntedYouList = async () => {
    try {
      const { wallets } = await whoHuntYouOut(axiosInstance, isMe ? undefined : (loadedAddress as string));

      setWhoHuntedYouList(wallets);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // handle 401 error here
        //TODO : refresh token
        onLogout();
        router.push('/login');
      } // console.log('🚀 ~ file: index.tsx:178 ~ getWhoHuntedYouList ~ error:', error);
    }

    return [];
  };

  useEffect(() => {
    if (loadedAddress) {
      getWhoHuntedYouList();
    }
  }, [loadedAddress, isMe]);

  useEffect(() => {
    if (pluginAddress && user?.address && pluginAddress?.toLowerCase() !== user?.address?.toLowerCase()) {
      openLogout();
    }
  }, [pluginAddress, user?.address]);

  return (
    <Default
      pageName="Home"
      myAddress={myAddress}
      showInfoOnLeft={!useSpots}
      showInfoOnRight={!useSpots}
      loadingCheckoutAddress={loadingCheckoutAddressList}
      checkoutAddressList={checkoutAddressList}
      whoHuntedYouList={whoHuntedYouList}
      huntingList={{ saved: savedGroupList, created: createdGroupList }}
      useSpots={useSpots}
      setUseSpots={setUseSpots}
    >
      <Box position="relative" width={['100%', '100%', useSpots ? '94%' : 1100]} h="100%">
        <Image
          alt="banner"
          className="absolute  z-0"
          width={'100%'}
          height={'100%'}
          left={[0, null, '20%']}
          top={[0, null, '100px']}
          src={'/shape-banner-center.png'}
        />
        {useSpots && claimed ? (
          // spots market
          <Box position={'relative'}>
            <Flex
              gap={4}
              alignSelf={'center'}
              justifyContent={'center'}
              position={'absolute'}
              top={[-4, -16]}
              left={0}
              right={0}
            >
              <Text
                fontWeight={useSpots ? 500 : 700}
                fontSize={[16, 20]}
                opacity={!useSpots ? 1 : 0.5}
                onClick={() => setUseSpots(false)}
                cursor={'pointer'}
                color={'#fff'}
                zIndex={100}
              >
                MY PROFILE
              </Text>
              <Text
                fontWeight={!useSpots ? 500 : 700}
                fontSize={[16, 20]}
                opacity={useSpots ? 1 : 0.5}
                onClick={() => setUseSpots(true)}
                cursor={'pointer'}
                color={'#fff'}
                zIndex={100}
              >
                PROFILE MARKETPLACE
              </Text>
            </Flex>
            <Trending showSidebar={true} hideShowSideBar={() => setUseSpots(false)} />
            <MarketList hideShowSideBar={() => setUseSpots(false)} />
          </Box>
        ) : (
          // profile
          <Box position={'relative'}>
            {claimed ? (
              <Flex
                gap={4}
                alignSelf={'center'}
                justifyContent={'center'}
                position={'absolute'}
                top={[-4, -16]}
                left={0}
                right={0}
              >
                <Text
                  fontWeight={useSpots ? 500 : 700}
                  fontSize={[16, 20]}
                  opacity={!useSpots ? 1 : 0.5}
                  onClick={() => setUseSpots(false)}
                  cursor={'pointer'}
                >
                  MY PROFILE
                </Text>
                <Text
                  fontWeight={!useSpots ? 500 : 700}
                  fontSize={[16, 20]}
                  opacity={useSpots ? 1 : 0.5}
                  onClick={() => setUseSpots(true)}
                  cursor={'pointer'}
                >
                  PROFILE MARKETPLACE
                </Text>
              </Flex>
            ) : (
              <></>
            )}
            {claimed && inbox?.length > 0 && (
              <Box px={10} pt={100}>
                <Flex justifyContent={'space-between'}>
                  <Text fontWeight={useSpots ? 500 : 700} fontSize={26} mb={6}>
                    My Messages
                  </Text>
                  <Box ml="auto">
                    <Button
                      style={{
                        background: 'transparent',
                      }}
                      cursor={'pointer'}
                      px={1}
                      onClick={setIsPand.off}
                    >
                      <ExpandIcon cursor={'pointer'} color={!isExpand ? '#82FCD3' : 'white'} />
                    </Button>
                    <Button
                      style={{
                        background: 'transparent',
                      }}
                      px={1}
                      cursor={'pointer'}
                      onClick={setIsPand.on}
                    >
                      <MenuIcon cursor={'pointer'} color={isExpand ? '#82FCD3' : 'white'} />
                    </Button>
                  </Box>
                </Flex>

                <ResponsiveCarousel
                  compaignsList={inbox}
                  isExpand={isExpand}
                  myAddress={myAddress || ''}
                  refetch={refetch}
                />
              </Box>
            )}

            {/* <Box display="flex" flexDirection="row" alignItems="center" pt="30px"> */}

            {/* </Box> */}

            <PhoneDrawer
              children={
                <SideInfoRight
                  myAddress={myAddress}
                  loadingCheckoutAddress={loadingCheckoutAddressList}
                  checkoutAddressList={checkoutAddressList}
                  whoHuntedYouList={whoHuntedYouList}
                  loadingWhoHuntedYou={false}
                  huntingList={{ saved: savedGroupList, created: createdGroupList }}
                  loadedAddress={address}
                />
              }
              showSearchIcon={true}
              toggleSearchDisplay={toggleSearchDisplay}
            />
            {/* NOTE: Dashboard 本體 */}

            {claimed ? <Trending showSidebar={false} hideShowSideBar={() => setUseSpots(false)} /> : <></>}

            <Box
              position="relative"
              p={'6'}
              px={[4, null, '12']}
              rounded="2xl"
              bg="#041320"
              mt={['4', null, '12']}
              mx={[4, null, null]}
            >
              {!isMe && myAddress ? (
                <Flex mb={10}>
                  <button onClick={handleBackClick}>
                    <Text fontSize={20}>{`< My Profile`}</Text>
                  </button>
                </Flex>
              ) : (
                <></>
              )}
              <Box
                display={{
                  base: 'none',
                  md: 'flex',
                }}
                className="relative  w-full mx-auto z-10"
                w={['330px', null, '100%']}
                flexDir={['column', null, 'row']}
                maxW="1017"
                alignItems={'center'}
                mb={10}
              >
                {/* <Input
                  p="6"
                  bg="rgba(255, 255, 255,1)"
                  rounded="full"
                  placeholder="-- Paste the wallet address here -- "
                  border="none"
                  color="#000"
                  onChange={handleAddressChange}
                  value={address}
                /> */}

                <FuzzySearchInput />
                {/* <Button
                  right="0"
                  position={['relative', null, 'absolute']}
                  colorScheme="purple"
                  bg="#6235D0"
                  rounded="full"
                  color="#fff"
                  fontSize="xs"
                  fontWeight="bold"
                  p="6"
                  px={['3', null, '12']}
                  mt={[3, null, 0]}
                  onClick={handleSearchClick}
                  zIndex={1}
                >
                  Search Wallet
                </Button> */}
              </Box>
              <div className="relative z-10">
                {loadedAddress && wallet.address ? (
                  <DashBoardProvider myAddress={myAddress} walletAddress={wallet.address} errorFn={handleError}>
                    <Platform
                      myAddress={myAddress}
                      address={wallet.address}
                      isHunted={isHunted}
                      onFollowClick={handleFollowClick}
                    />
                  </DashBoardProvider>
                ) : (
                  <Flex minH="600px" alignItems="center" justifyContent="center">
                    <Loading />
                  </Flex>
                )}
              </div>
            </Box>

            {/* NOTE: Import users Modal */}
            <Modal isOpen={isUploadUsersOpen} onClose={onUploadUsersClose}>
              <ModalOverlay
                position="fixed"
                top="0"
                left="0"
                right="0"
                bottom="0"
                w="100%"
                h="100%"
                backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
                zIndex="1000"
              />
              <ModalContent
                backgroundColor={'#000'}
                border={['none', null, '1px solid #82FCD3']}
                rounded="2xl"
                mx={[10, 0, 0]}
              >
                <ModalHeader
                  fontWeight="bolder"
                  fontSize={['3xl', null, '4xl']}
                  paddingBottom="0px"
                  alignSelf={['center', null, 'start']}
                >
                  Import Users
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text fontSize="sm" textAlign={['center', null, 'start']}>
                    Import from a CSV file
                  </Text>
                  {isUploaded ? (
                    <Box my="4" px="4" py="12" border="1px dashed #949494" rounded="md">
                      <Flex alignItems="center" mx="auto">
                        <div>
                          <Image alt="checked" src="/ic-checked-circle.png" width={30} height={30}></Image>
                        </div>
                        <Text ml="2" fontSize="12px">
                          Upload Successfully, press “Import Users” to continue
                        </Text>
                      </Flex>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center">
                      <Box
                        {...getRootProps({
                          className:
                            'dropzone flex justify-center items-center w-full  border border-2 border-dashed my-6 rounded-md cursor-pointer',
                        })}
                        h={['333px', null, '140px']}
                      >
                        <input {...getInputProps()} />
                        <Flex alignItems="center" flexDir={['column', null, 'row']}>
                          <Image alt="download" src="/ic-download.png" width={30} height={30} className="mr-2"></Image>
                          <Text mr="2" color="#898989">
                            Drop .csv file here or{' '}
                          </Text>
                          <Text textDecoration="underline" color="#82FCD3">
                            click to upload
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="center">
                    <Button
                      right="0"
                      colorScheme="purple"
                      bg="#6235D0"
                      rounded="full"
                      color="#fff"
                      fontSize="xs"
                      fontWeight="bold"
                      p="1"
                      px="12"
                      mt="3"
                      zIndex={40}
                      onClick={handleUsersImport}
                      disabled={files.length === 0}
                    >
                      Import Users
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="center" my="4">
                    <a href="https://storage.googleapis.com/profile-bluwhale/import_wallets.csv" download="CSV Sample">
                      <Text fontSize="sm" textDecoration="underline" mb="6" color="#82FCD3">
                        Download the CSV Sample
                      </Text>
                    </a>
                  </Box>
                </ModalBody>

                {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter> */}
              </ModalContent>
            </Modal>

            {/* NOTE: Too many request */}
            <Modal isOpen={isTooManyRequestsOpen} onClose={onTooManyRequestsClose}>
              <ModalOverlay
                position="fixed"
                top="0"
                left="0"
                right="0"
                bottom="0"
                w="100%"
                h="100%"
                backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
                zIndex="1000"
              />
              <ModalContent backgroundColor="#fff" rounded="2xl">
                <ModalHeader fontWeight="bolder" fontSize="3xl" paddingBottom="0px" color="#000">
                  <Text className="text-center" mt="8" mb="3">
                    Too many requests!
                  </Text>
                </ModalHeader>
                <ModalCloseButton display="none" />
                <ModalBody>
                  <Text w="290px" color="#676767" fontSize="sm" mb="7" mx="auto" textAlign="center">
                    Our servers are receiving too many requests right now. Please try again later.
                  </Text>
                  <Box display="flex" justifyContent="center" mb="2">
                    <Button
                      right="0"
                      colorScheme="purple"
                      bg="#6235D0"
                      rounded="full"
                      color="#fff"
                      fontSize="xs"
                      fontWeight="bold"
                      w="310px"
                      zIndex={40}
                      onClick={handleTooManyRequestsConfirm}
                      disabled={files.length === 0}
                    >
                      OK
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="center" mb="8">
                    <Link href="mailto:info@bluwhale.com" passHref legacyBehavior className="w-full">
                      <a target="_blank" rel="noopener noreferrer">
                        <button className="mt-3 text-sm text-[#6235D0] underline">Contact Us</button>
                      </a>
                    </Link>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>

            {/* NOTE: not processed group */}
            <Modal isOpen={isProcessing} onClose={closeProcessing}>
              <ModalOverlay
                bg="rgba(4, 19, 32, 0.2)"
                backdropFilter="blur(10px) hue-rotate(90deg)"
                position="fixed"
                top="0"
                left="0"
                right="0"
                bottom="0"
                w="100%"
                h="100%"
                backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
                zIndex="1000"
              />
              <ModalContent
                rounded="2xl"
                bg="transparent"
                border={'none'}
                p={0}
                w={'100vw'}
                h={'100vh'}
                shadow={'none'}
              >
                <ModalBody display={'flex'} alignItems={'center'} flexDir={'column'} p={0} m={0}>
                  <Avatar src={searchProjectInfo?.image_url || '/images/default_avatar.png'} />
                  <Text fontSize={18}>{searchProjectInfo?.name || getEllipsisTxt(address)}</Text>
                  <Text fontSize={18} mt={4}>
                    This project is currently in progress. Please come back in 24 hours or wait for a notification via
                    email
                  </Text>
                  <Input
                    p={[3, null, 6]}
                    bg="rgba(217, 217, 217, 1)"
                    rounded="md"
                    placeholder="-- Email address -- "
                    border="none"
                    value={email}
                    color="#000"
                    h={50}
                    minW={['100%', null, '400px']}
                    onChange={handleEmailChange}
                    mt={10}
                    sx={{
                      '::placeholder': {
                        textAlign: 'center',
                      },
                    }}
                  />
                  <Button
                    right="0"
                    colorScheme="purple"
                    bg="#6235D0"
                    rounded="full"
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                    p="6"
                    px={['3', null, '12']}
                    onClick={handleUploadEmail}
                    disabled={email.length === 0}
                    mt={3}
                    mb={10}
                  >
                    Notify me
                  </Button>

                  <Text fontSize={14}>While you wait, take a look at the projects below.</Text>
                  <Box display={'flex'} gap={6} flexDir={'row'} cursor={'pointer'} mt={4}>
                    {checkoutAddressList.map((item, index) => (
                      <Avatar
                        //@ts-ignore
                        src={item.img_url}
                        //@ts-ignore
                        key={item.address + index}
                        //@ts-ignore
                        onClick={() => router.push(`/group/${item.address}`)}
                      />
                    ))}
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        )}
      </Box>

      <Modal isOpen={showLogout} onClose={closeLogout}>
        <ModalOverlay
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          w="100%"
          h="100%"
          backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
          zIndex="1000"
        />
        <ModalContent>
          <ModalHeader color={'#CF4C54'}>Change Wallet Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              We've noticed that you've switched to a different address. Would you like to log in again using this new
              wallet address?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={closeLogout}
              px={10}
              bg={'transparent'}
              color={'white'}
              borderWidth={'1'}
              borderColor={'white'}
              variant="outline"
            >
              No
            </Button>
            <Button
              px={10}
              onClick={onLogout}
              bg={'transparent'}
              color={'white'}
              borderWidth={'1'}
              borderColor={'white'}
              variant="outline"
            >
              Sign out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Default>
  );
};

export default HomePage;
